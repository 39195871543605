import './Modal.css'

const Modal = ({
  isOpen,
  onAccept,
  acceptLabel,
  onCancel,
  cancelLabel,
  title,
  label,
  children,
  image,
}) => {
  const handleModalDialogClick = e => {
    e.stopPropagation()
  }
  return (

    <div className={`modal ${isOpen && 'modal-open'}`} onClick={onCancel}>
      <div className='modal_dialog' onClick={handleModalDialogClick}>
        {image ? (
          <div>
            <img src={image} className='image'></img>{' '}
          </div>
        ) : (
          ''
        )}
        <div className='modal-title'>
          <h3>{title}</h3>
        </div>
        <div className='modal-input'>{label}</div>

        {children}
        {cancelLabel ? (
          <button className='btn btn-danger modal-input' onClick={onCancel}>
            {cancelLabel}
          </button>
        ) : (
          ''
        )}
        <button className='btn btn-dark modal-input' onClick={onAccept}>
          {acceptLabel}
        </button>
      </div>
    </div>
  )
}

export default Modal
