import axios from 'axios'
const URL = process.env.REACT_APP_SOCKET_URL

export default class Methods {
  static async getUsers(data) {
    return await axios.post(`${URL}/users/getUsers`, data)
  }

  static async getUser(email) {
    return await axios.post(`${URL}/users/getUser`, email)
  }

  static async addUser(data) {
    return await axios.post(`${URL}/users/addUser`, data)
  }

  static async updateUser({ user, isPasswordEdited }) {
    return await axios.put(`${URL}/users/updateUser`, {
      user,
      isPasswordEdited,
    })
  }

  static async deleteUser(data) {
    return await axios.post(`${URL}/users/deleteUser`, data)
  }

  static async signUp(data) {
    console.log(data)
    return await axios.post(`${URL}/users/signUp`, data)
  }

  static async getClientTypes() {
    return await axios.get(`${URL}/clientType/getClientTypes`)
  }

  static async quicksightGetEmbedURL(email) {
    return await axios.post(`${URL}/quicksight/getEmbedURL`, {
      email,
    })
  }

  static async generatePresignedURL(data) {
    return await axios.post(`${URL}/s3/generatePresignedURL`, data)
  }

  static async getDashboardData() {
    return await axios.get(`${URL}/dashboard/getDashboardData`)
  }

  static async getIndividualDashboardData(id) {
    return await axios.post(`${URL}/dashboard/getIndividualDashboardData`, id)
  }

  static async getDashboardUpdateData(data) {
    return await axios.post(`${URL}/dashboard/getDashbordUpdateData`, data)
  }

  static async updateDashboard(data) {
    return await axios.put(`${URL}/dashboard/updateDashboard`, data)
  }

  static async eraseDashboard(data) {
    return await axios.post(`${URL}/dashboard/eraseDashboard`, data)
  }

  static async getFilesReference(data) {
    return await axios.post(`${URL}/filesReference/getFilesReference`, data)
  }

  static async getFilesReferences(data) {
    return await axios.post(`${URL}/filesReference/getFilesReferences`, data)
  }

  static async getFilesTrainings(data) {
    return await axios.post(`${URL}/filesTraining/getFilesTrainings`, data)
  }

  static async postFilesReference(data, userId) {
    return await axios.post(
      `${URL}/filesReference/postFilesReference?userId=${userId}`,
      data
    )
  }

  static async postSurvey(data, idTrainingVideo, userId, userName) {
    console.log(data)
    const payload = {
      idTrainingVideo,
      data,
      userId,
      userName,
    }
    return await axios.post(
      `${URL}/surveyTraining/postSurveyTraining`,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    )
  }

  static async getSurveyResults(id, userId) {
    return await axios.get(
      `${URL}/surveyTraining/getSurveyResults?id=${id}&userId=${userId}`
    )
  }

  static async getSurvey(id) {
    return await axios.get(`${URL}/surveyTraining/getSurvey?id=${id}`)
  }

  static async postResponse(data, idTrainingVideo, userId, userName) {
    console.log(data)
    const payload = {
      idTrainingVideo,
      data,
      userId,
      userName,
    }
    return await axios.post(`${URL}/surveyTraining/postResponse`, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  static async postFilesTraining(data, packageId, userId) {
    console.log(data)
    const body = {
      ...data,
      packageId,
    }
    return await axios.post(
      `${URL}/filesTraining/postFilesTraining?userId=${userId}`,
      body
    )
  }

  static async putFilesReference(data, userId, isFileChanged, isImageChanged) {
    return await axios.put(
      `${URL}/filesReference/putFilesReference?userId=${userId}&isFileChanged=${isFileChanged}&isImageChanged=${isImageChanged}`,
      data
    )
  }

  static async deleteFilesReference(idFile) {
    return await axios.delete(
      `${URL}/filesReference/deleteFilesReference?id=${idFile}`
    )
  }

  static async deleteFilesTraining(idFile) {
    return await axios.delete(
      `${URL}/filesTraining/deleteFilesTraining?id=${idFile}`
    )
  }

  static async getTimeTraining(idFile, userId) {
    return await axios.get(
      `${URL}/filesTraining/getTimeTraining?id=${idFile}&userId=${userId}`
    )
  }

  static async setTimeTraining(idFile, userId, time) {
    return axios.post(`${URL}/filesTraining/setTimeTraining`, null, {
      params: {
        id: idFile,
        userId,
        time,
      },
    })
  }

  static async putFileToS3(url, file, type) {
    const tmpAxiosInstance = axios.create()
    return await tmpAxiosInstance.put(url, file, {
      headers: {
        'Content-Type': type,
      },
    })
  }
}
