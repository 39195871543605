/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router'
import './TrainingFilesDetail.css'
import { useState, useEffect, useContext } from 'react'
import ModalSurvey from '../../../components/ModalSurvey'
import { useParams } from 'react-router-dom'
import Methods from '../../../utils/Methods'
import { UserContext } from '../../../context/UserContext'

export default function TrainingFilesDetail() {
  const navigate = useNavigate()
  const surveyParams = useParams()
  console.log('surveyParams', surveyParams.id)
  const context = useContext(UserContext)
  // Datos iniciales con resultados vacíos
  const [surveyData, setSurveyData] = useState([])

  // Estado para controlar el modal
  const [showModal, setShowModal] = useState(false)
  const [currentUserDetails, setCurrentUserDetails] = useState([])

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const userId = context.user.id
        const response = await Methods.getSurveyResults(surveyParams.id, -1)
        const result = response.data.result
        console.log(response.data.result)
        // Formateamos los datos si 'result' es un array
        const formattedData = result.map(item => ({
          username: item.userName,
          surveyName: `Encuesta para Video ID ${item.idTrainingVideo}`,
          correctAnswers: item.trainingResponse.correctCount,
          incorrectAnswers: item.trainingResponse.incorrectCount,
          details: item.trainingResponse, // Detalles adicionales si es necesario
        }))

        setSurveyData(formattedData)
      } catch (error) {
        console.error('Error al obtener datos:', error)
      }
    }
    fetchSurveyData()
  }, [surveyParams.id])

  const handleShowDetails = (details) => {
    console.log(details)
    setCurrentUserDetails(details)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentUserDetails([])
  }

  return (
    <>
      <div className="whiteScreen">
        <div className="body">
          <div className="flex">
            <button className="back-arrow" onClick={() => navigate('/TrainingFiles')}>
              &lt;
            </button>
            <header>Resultados de Encuestas</header>
          </div>
          <div className="main">
            <h2>Tabla de Resultados</h2>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={headerCellStyle}>Usuario</th>
                  <th style={headerCellStyle}>Nombre de Encuesta</th>
                  <th style={headerCellStyle}>Correctas</th>
                  <th style={headerCellStyle}>Incorrectas</th>
                  {/* <th style={headerCellStyle}>Acciones</th> */}
                </tr>
              </thead>
              <tbody>
                {surveyData.map((user, index) => (
                  <tr key={index} style={rowStyle}>
                    <td style={cellStyle}>{user.username}</td>
                    <td style={cellStyle}>{user.surveyName}</td>
                    <td style={cellStyle}>{user.correctAnswers}</td>
                    <td style={cellStyle}>{user.incorrectAnswers}</td>
                    {/* <td style={cellStyle}>
                      <button
                        style={buttonStyle}
                        onClick={() => handleShowDetails(user.details)}
                      >
                        Ver Detalle
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Mostrar el modal con los detalles */}
      <ModalSurvey
        isOpen={showModal}
        onCancel={handleCloseModal}
        cancelLabel="Cerrar"
        title="Detalles de la Encuesta"
        currentUserDetails={currentUserDetails}
      />
    </>
  )
}

// Estilos CSS
const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  borderRadius: '10px',
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
}

const headerCellStyle = {
  padding: '15px',
  backgroundColor: '#f9fafb',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '2px solid #eee',
}

const rowStyle = {
  transition: 'background-color 0.3s',
}

const cellStyle = {
  padding: '15px',
  borderBottom: '1px solid #eee',
  textAlign: 'left',
}

const buttonStyle = {
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  padding: '8px 12px',
  borderRadius: '5px',
  cursor: 'pointer',
}
