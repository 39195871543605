import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTrashCan,
  faListCheck,
  faPlay,
  faPenToSquare,
  faPencil,
} from '@fortawesome/free-solid-svg-icons'
import './TrainingFiles.css'
import { Link } from 'react-router-dom'
import Methods from '../../utils/Methods'
import { useLocation } from 'react-router'
import { useEffect, useState, useContext } from 'react'
import Modal from '../../components/Modal'
import ModalVideo from '../../components/ModalVideo'
import { useModal } from '../../hooks/useModal'
import { notify } from '../../utils/notify'
import { Pagination } from '../../components/Pagination'
import Spinner from '../../components/Spinner'
import ModalSurvey from '../../components/ModalSurvey'
import { UserContext } from '../../context/UserContext'
import { PDFDocument } from 'pdf-lib'

export default function FilesReferences({ state }) {
  const [currentPage, setCurrentPage] = useState(0)
  const context = useContext(UserContext)
  const [numberItems, setNumberItems] = useState(0)
  const [filesFiltered, setFilesFiltered] = useState(null)
  const [isOpenModal, openModal, closeModal, data] = useModal()
  const [isOpenModalVideo, openModalVideo, closeModalVideo, dataVideo] =
    useModal()
  const location = useLocation()
  // eslint-disable-next-line no-unused-vars
  const { user, action } = location.state || {}
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [packageInfo, setPackageInfo] = useState([])

  const [showModalSurvey, setShowModalSurvey] = useState(false) // Controla la visibilidad del modal
  const [currentVideo, setCurrentVideo] = useState(null)
  const [newUser, setNewUser] = useState({
    company: '',
    name: '',
    password: '',
    clientTypeId: '',
    status: 'active',
    accessEnd: '',
  })

  useEffect(() => {
    getFiles()
  }, [])

  useEffect(() => {
    setNewUser({ ...newUser, ...user })
    context.getClientTypes().then(clientTypes => {
      getPackageInfo(user?.clientTypeId, clientTypes)
    })
  }, [])

  const getPackageInfo = async (clientTypeId, clientTypes) => {
    const packageInfo = clientTypes[clientTypeId - 1]?.info
    setPackageInfo(packageInfo ? packageInfo.split(',') : [''])
  }

  const getFiles = async (offset = 0) => {
    setIsLoading(true)
    const response = await Methods.getFilesTrainings({
      limit: 10,
      offset,
      userType: context.user.userType,
      userId: context.user.id,
      clientTypeId: context.user.clientTypeId,
    })

    for (let i = 0; i < response.data.rows.length; i++) {
      try {
        const responseSurvey = await Methods.getSurveyResults(
          response.data.rows[i].id,
          context.user.id
        )
        response.data.rows[i].cal =
          responseSurvey.data.result[0].trainingResponse.correctCount
      } catch (error) {
        console.log('error', error)
      }
    }
    setCurrentPage(offset)
    setNumberItems(response.data.count)
    setFilesFiltered(response.data.rows)
    setIsLoading(false)
    console.log('sssRES', response.data.rows)
  }

  const deleteFile = async event => {
    setIsLoading(true)
    closeModal()
    console.log('event', event)
    await Methods.deleteFilesTraining(event.id)
      .then(() => {
        notify('Video eliminado correctamente', 'success')
        getFiles()
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  const downloadPdfWithUserName = async fileItem => {
    // Cargar el archivo PDF existente desde la carpeta public
    const url = '/const.pdf'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    console.log(fileItem)
    // Crear un documento PDF a partir del archivo existente
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Obtener la primera página del PDF
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Agregar el nombre del usuario al PDF
    const userName = context.user.name || 'Usuario' // Obtener el nombre del usuario
    firstPage.drawText(`${userName}`, {
      x: 800,
      y: 900,
      size: 40,
    })

    firstPage.drawText(`${fileItem.nameFile}`, {
      x: 800,
      y: 450,
      size: 40,
    })

    // Crear el PDF modificado
    const pdfBytes = await pdfDoc.save()

    // Crear un nuevo archivo PDF utilizando el PDF generado con pdf-lib
    const newPdfDoc = await PDFDocument.load(pdfBytes)

    // Guardar el PDF resultante como archivo
    const pdfUrl = await newPdfDoc.saveAsBase64({ dataUri: true })

    // Descargar el PDF resultante
    const link = document.createElement('a')
    link.href = pdfUrl
    link.download = `${fileItem.nameFile}-${userName}.pdf`
    link.click()
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading && <Spinner />}
        <Modal
          isOpen={isOpenModal}
          title='Eliminar Video'
          label='¿Está seguro que desea eliminar el Video?'
          onCancel={closeModal}
          cancelLabel='Cancelar'
          onAccept={e => deleteFile(data)}
          acceptLabel='Aceptar'
        />
        <ModalVideo
          isOpen={isOpenModalVideo}
          title=''
          label=''
          url={dataVideo.nameFile}
          onCancel={closeModalVideo}
          cancelLabel='Cerrar'
          onAccept={e => deleteFile(dataVideo)}
          acceptLabel=''
          id={dataVideo.id}
          userId={dataVideo}
        />
        <div className='body'>
          <header>Capacitación</header>
          <div className='main'>
            <div className='flex'>
              {context.user.userType === 'admin' && (
                <>
                  <div className='button'>
                    <Link to={'new'} state={{ action: 'create' }}>
                      <button className='primary-btn blue-btn'>
                        <FontAwesomeIcon icon={faPlus} className='plusIcon' />
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </div>

            <br />
            <div className='header'>
              <div className='nameFile'>Nombre del Video</div>
              {context.user.userType === 'admin' && (
                <>
                  <div className='updatedBy'>Actualizado por</div>
                </>
              )}
              <div className='updateDate'>Ultima carga</div>
              <div className='play'></div>
              <div className='actionsEdit'>Contestar</div>
              {context.user.userType === 'admin' && (
                <>
                  <div className='actionsEdit'>Cuestionario</div>
                  <div className='actionsEdit'>Resultados</div>
                  <div className='actionsDelete'>Eliminar</div>
                </>
              )}
              <div className='actionsEdit'>Insignia</div>
            </div>
            {filesFiltered?.map(fileItem => (
              <div className='flex-row' key={fileItem.id}>
                <div className='nameFile'>{fileItem.nameFile}</div>
                {context.user.userType === 'admin' && (
                  <>
                    <div className='updatedBy'>{fileItem.user.name}</div>
                  </>
                )}
                <div className='updateDate'>{fileItem.updateDate}</div>
                <div className='play'>
                  <FontAwesomeIcon
                    icon={faPlay}
                    onClick={e =>
                      openModalVideo({
                        id: fileItem.id,
                        nameFile:
                          'https://bp-prod-caintra-pesb.s3.us-east-1.amazonaws.com/system/files/' +
                          fileItem.referenceFile,
                        userId: fileItem.user,
                      })
                    }
                  ></FontAwesomeIcon>
                </div>
                <div className='actionsDelete'>
                  <FontAwesomeIcon
                    icon={faPencil}
                    className='actionIcon'
                    onClick={e => {
                      setCurrentVideo(fileItem.id)
                      setShowModalSurvey(true)
                    }}
                  />
                </div>

                {context.user.userType === 'admin' && (
                  <>
                    <div className='actionsEdit'>
                      <Link
                        to={`Survey/${fileItem.id}`}
                        state={{ fileItem, action: 'edit' }}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className='actionIcon'
                        />
                      </Link>
                    </div>
                    <div className='actionsEdit'>
                      <Link
                        to={`SurveyResult/${fileItem.id}`}
                        state={{ fileItem, action: 'edit' }}
                      >
                        <FontAwesomeIcon
                          icon={faListCheck}
                          className='actionIcon'
                        />
                      </Link>
                    </div>
                    <div className='actionsDelete'>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className='actionIcon'
                        onClick={e =>
                          openModal({
                            id: fileItem.id,
                            nameFile: fileItem.nameFile,
                          })
                        }
                      />
                    </div>
                  </>
                )}
                {fileItem.cal > 0 && (
                  <img
                    src='insignia-color.png'
                    style={{
                      width: '35px',
                      height: '35px',
                      marginTop: '-10px',
                    }}
                    alt='Logo'
                    onClick={() => downloadPdfWithUserName(fileItem)}
                  />
                )}
                {(fileItem.cal === 0 || fileItem?.cal === undefined) && (
                  <img
                    src='insignia-grises.png'
                    style={{
                      width: '35px',
                      height: '35px',
                      marginTop: '-10px',
                    }}
                    alt='Logo'
                    onClick={() =>
                      alert(
                        'No ha completado el cuestionario o su calificación no es aprobatoria'
                      )
                    }
                  />
                )}
              </div>
            ))}
          </div>
          <div className='pagination-div'>
            <Pagination
              numberItems={numberItems}
              currentPage={currentPage}
              pageSize={10}
              handleOffset={e => getFiles(currentPage + e)}
            />
          </div>
        </div>
      </div>

      <ModalSurvey
        isOpen={showModalSurvey}
        onCancel={() => setShowModalSurvey(false)}
        cancelLabel='Cerrar'
        title='Encuesta'
        csvContent={[]} // Pasamos los datos CSV al modal
        videoId={currentVideo}
      />
    </>
  )
}
