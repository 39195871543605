import './ModalResults.css'

const ModalResults = ({
  isOpen,
  onCancel,
  cancelLabel,
  title,
  csvContent, // Recibe los datos CSV
}) => {
  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''}`} onClick={onCancel}>
      <div className="modal_dialog" onClick={(e) => e.stopPropagation()}>
        <h3>{title}</h3>

        {/* Verificar si hay datos CSV para mostrar */}
        {csvContent && csvContent.length > 0 ? (
          <div>
            <h4></h4>
            <table style={tableStyle}>
              <thead>
                <tr>
                  {/* Crear las cabeceras dinámicamente según las columnas del CSV */}
                  {Object.keys(csvContent[0]).map((key, index) => (
                    <th key={index} style={headerCellStyle}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Crear las filas dinámicamente según el contenido del CSV */}
                {csvContent.map((row, index) => (
                  <tr key={index} style={rowStyle}>
                    {Object.values(row).map((value, i) => (
                      <td key={i} style={cellStyle}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No hay datos para mostrar.</p>
        )}

        {cancelLabel && (
          <button className="btn btn-danger" onClick={onCancel}>
            {cancelLabel}
          </button>
        )}
      </div>
    </div>
  )
}

export default ModalResults
const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  borderRadius: '10px',
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
}

const headerCellStyle = {
  padding: '15px',
  backgroundColor: '#f9fafb',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '2px solid #eee',
}

const rowStyle = {
  transition: 'background-color 0.3s',
}

const cellStyle = {
  padding: '15px',
  borderBottom: '1px solid #eee',
  textAlign: 'left',
}
