/* eslint-disable no-unused-vars */
import { useLocation, useNavigate } from 'react-router'
import './TrainingFilesDetail.css'
import { useEffect, useState, useRef, useContext } from 'react'
import Methods from '../../../utils/Methods'
import { notify } from '../../../utils/notify'
import Spinner from '../../../components/Spinner'
import { UserContext } from '../../../context/UserContext'
import Select from 'react-select'
import Papa from 'papaparse'
import ModalResults from '../../../components/ModalResults'
import { useParams } from 'react-router-dom'

export default function TrainingFilesDetail() {
  const location = useLocation()
  const surveyParams = useParams()
  const { fileItem, action } = location.state
  const [fileData, setFileData] = useState('')
  const [nameFileData, setNameFileData] = useState('')
  const [csvContent, setCsvContent] = useState([]) // Almacena el contenido del CSV
  const [showModal, setShowModal] = useState(false) // Controla la visibilidad del modal
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const inputFileRef = useRef(null)
  const context = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (context.user) {
      setUserId(context.user.id)
    }
  }, [])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFileData(file)
    setNameFileData(file.name)

    if (file && file.type === 'text/csv') {
      const reader = new FileReader()
      reader.onload = (e) => {
        const csvText = e.target.result
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const columns = Object.keys(result.data[0])
            if (columns.length !== 5) {
              notify('El archivo CSV debe contener 5 columnas(1 pregunta, 3 respuestas y 1 respuesta correcta)', 'error')
              setCsvContent([])
              setShowModal(false)
              return
            }
            setCsvContent(result.data)
            setShowModal(true)
          },
          error: (err) => {
            console.error('Error al procesar el CSV:', err)
            notify('Error al leer el archivo CSV.', 'error')
          },
        })
      }
      reader.readAsText(file)
    } else {
      notify('Por favor, selecciona un archivo CSV.', 'warning')
    }

    inputFileRef.current.value = null
  }

  const handleFileIconClick = async() => {
    inputFileRef.current.click()
  }

  const saveFile = async () => {
    setIsLoading(true)
    console.log('csvContent', csvContent)
    if (csvContent.length === 0) {
      setIsLoading(false)
      notify('No hay contenido válido para guardar.', 'warning')
      return
    }
    try {
      const response = await Methods.postSurvey(csvContent, surveyParams.id)
      if (response.status === 200) {
        notify('Datos guardados correctamente.', 'success')
      } else {
        notify('Hubo un problema al guardar los datos.', 'error')
      }
    } catch (error) {
      console.error('Error al guardar los datos:', error)
      notify('Error al guardar los datos.', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="whiteScreen">
        {isLoading && <Spinner />}
        <div className="body">
          <div className="flex">
            <button
              className="back-arrow"
              onClick={() => navigate('/TrainingFiles')}
            >
              &lt;
            </button>
            <header>{nameFileData || 'Cargar nueva encuesta'}</header>
          </div>
          <div className="main">
            <div className="d-flex align-items-center">
              <div className="col-3 m-2">Nombre del archivo</div>
              <input
                className="input col-8 mb-3 mt-2"
                value={nameFileData}
                readOnly
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="col-3 m-2">Adjuntar archivo CSV</div>
              <button
                className="input col-3"
                style={{ backgroundColor: 'skyblue' }}
                onClick={handleFileIconClick}
              >
                Seleccione Archivo
              </button>
              <input
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                ref={inputFileRef}
                onChange={handleFileChange}
              />
            </div>
            <button className="btn btn-dark mt-5" onClick={saveFile}>
              Guardar
            </button>
          </div>
        </div>
      </div>

      {/* Modal personalizado para mostrar el contenido del CSV */}
      <ModalResults
        isOpen={showModal}
        onCancel={() => setShowModal(false)}
        cancelLabel="Cerrar"
        title="Vista previa de la encuesta"
        csvContent={csvContent} // Pasamos los datos CSV al modal
      />

    </>
  )
}
