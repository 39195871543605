import './App.css'
import Login from './pages/Login/Login'
import Dashboard from './pages/Dashboard/Dashboard'
import DashboardUpdate from './pages/DashboardUpdate/DashboardUpdate'
import Amplify from 'aws-amplify'
import { getPoolData } from './utils/poolConfig.js'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { SideBar } from './components/SideBar'
import {
  faUserGroup,
  faHandshake,
  faDownload,
  faList,
  faUpload,
  faRefresh,
  faVideo,
} from '@fortawesome/free-solid-svg-icons'
import { useContext, useState } from 'react'
import Surveys from './pages/Surveys/Surveys'
import Downloads from './pages/Downloads/Downloads'
import Clients from './pages/Clients/Clients'
import Admins from './pages/Admins/Admins'
import AdminDetail from './pages/Admins/AdminDetail/AdminDetail'
import DownloadsFilesDetail from './pages/DownloadsFiles/DownloadsFilesDetail/DownloadsFilesDetail'
import TrainingFilesDetail from './pages/TrainingFiles/TrainingFilesDetail/TrainingFilesDetail'
import SurveyFilesDetail from './pages/TrainingFiles/TrainingFilesDetail/SurveyFilesDetail'
import ResultFilesDetail from './pages/TrainingFiles/TrainingFilesDetail/ResultFilesDetail'

import TrainingFiles from './pages/TrainingFiles/TrainingFiles'
import ClientDetail from './pages/Clients/ClientDetail/ClientDetail'
import DownloadsFiles from './pages/DownloadsFiles/DownloadsFiles'
import { classNames } from './utils/classNames'
import { UserContext } from './context/UserContext'

const poolConfig = getPoolData()
console.log(poolConfig)

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: poolConfig.Region,
    userPoolId: poolConfig.UserPoolId,
    userPoolWebClientId: poolConfig.ClientId,
  },
})

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return <Navigate to='/' replace />
  }
  return children
}
const ClientProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token')
  const role = JSON.parse(localStorage.getItem('user'))?.userType || undefined
  if (!token) {
    return <Navigate to='/' replace />
  }
  if (role !== 'admin') {
    return <Navigate to='/dashboard' replace />
  }
  return children
}

function App() {
  const [sideBarExpanded, setSideBarExpanded] = useState(true)
  const context = useContext(UserContext)

  const items = [
    {
      id: '1',
      label: 'Dashboard',
      icon: faList,
      url: '/dashboard',
    },
    {
      id: '2',
      label: 'Descargables',
      icon: faDownload,
      url: '/downloads',
    },
  ]

  if (context.user?.userType === 'admin') {
    items.push(
      {
        id: '3',
        label: 'Actualización de Tableros',
        icon: faUpload,
        url: '/dashboardUpdate',
      },
      {
        id: '4',
        label: 'Actualización de Descargables',
        icon: faRefresh,
        url: '/downloadsFiles',
      },
      {
        id: '5',
        label: 'Clientes',
        icon: faHandshake,
        url: '/clients',
      },
      {
        id: '6',
        label: 'Administradores',
        icon: faUserGroup,
        url: '/admins',
      },
      {
        id: '7',
        label: 'Capacitaciones',
        icon: faVideo,
        url: '/TrainingFiles',
      }
    )
  } else {
    items.push(
      {
        id: '4',
        label: 'Usuario',
        icon: faHandshake,
        url: '/clients/:id',
      },
      {
        id: '7',
        label: 'Capacitaciones',
        icon: faVideo,
        url: '/TrainingFiles',
      }
    )
  }

  return (
    <Router>
      <div className='flex'>
        {context.token ? (
          <SideBar
            items={items}
            handleSideBarExpanded={e => setSideBarExpanded(e)}
          />
        ) : (
          ''
        )}
        <div
          className={classNames(
            context.token ? '' : 'none',
            sideBarExpanded
              ? 'content content-collapsed'
              : 'content content-expanded'
          )}
        >
          <Routes>
            <Route index element={<Login />} />
            <Route
              path='/dashboard/:firstTime'
              element={
                <ProtectedRoute>
                  <Dashboard state />
                </ProtectedRoute>
              }
            />
            <Route
              path='/surveys'
              element={
                <ProtectedRoute>
                  <Surveys />
                </ProtectedRoute>
              }
            />
            <Route
              path='/downloads'
              element={
                <ProtectedRoute>
                  <Downloads />
                </ProtectedRoute>
              }
            />
            <Route
              path='/dashboardUpdate'
              element={
                <ClientProtectedRoute>
                  <DashboardUpdate />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/clients'
              element={
                <ClientProtectedRoute>
                  <Clients />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/clients/:id'
              element={
                <ProtectedRoute>
                  <ClientDetail state />
                </ProtectedRoute>
              }
            />
            <Route
              path='/admins'
              element={
                <ClientProtectedRoute>
                  <Admins />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/admins/:id'
              element={
                <ClientProtectedRoute>
                  <AdminDetail state />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/downloadsFiles'
              element={
                <ClientProtectedRoute>
                  <DownloadsFiles state />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/downloadsFiles/:id'
              element={
                <ClientProtectedRoute>
                  <DownloadsFilesDetail state />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/TrainingFiles'
              element={
                <ProtectedRoute>
                  <TrainingFiles />
                </ProtectedRoute>
              }
            />
            <Route
              path='/TrainingFiles/:id'
              element={
                <ClientProtectedRoute>
                  <TrainingFilesDetail state />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/TrainingFiles/Survey/:id'
              element={
                <ClientProtectedRoute>
                  <SurveyFilesDetail state />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/TrainingFiles/SurveyResult/:id'
              element={
                <ClientProtectedRoute>
                  <ResultFilesDetail state />
                </ClientProtectedRoute>
              }
            />
            <Route
              path='/Videos'
              element={
                <ProtectedRoute>
                  <videosFiles />
                </ProtectedRoute>
              }
            />
            <Route
              path='*'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
      <ToastContainer />
    </Router>
  )
}

export default App
