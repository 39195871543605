/* eslint-disable no-unused-vars */
import { useLocation, useNavigate } from 'react-router'
import './TrainingFilesDetail.css'
import { useEffect, useState, useRef, useContext } from 'react'
import Methods from '../../../utils/Methods'
import { notify } from '../../../utils/notify'
import Spinner from '../../../components/Spinner'
import { UserContext } from '../../../context/UserContext'
import Select from 'react-select'

export default function TrainingFilesDetail() {
  const location = useLocation()

  const { fileItem, action } = location.state
  const [fileData, setFileData] = useState('')
  const [imageData, setImageData] = useState('')
  const [nameFileData, setNameFileData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [selectedValue, setSelectedValue] = useState(null)
  const [newFile, setNewFile] = useState({
    id: '0',
    nameFile: '',
    nameFileData: '',

  })
  const selectStyle = {
    control: base => ({
      ...base,
      borderRadius: 10,
      paddingLeft: 10,
    }),
  }
  const [isFileChanged, setIsFileChanged] = useState(false)
  const [isImageChanged, setIsImageChanged] = useState(false)
  const inputFileRef = useRef(null)
  const inputImageRef = useRef(null)
  const context = useContext(UserContext)

  const navigate = useNavigate()

  useEffect(async() => {
    setUserId(context.user.id)

    if (action !== 'create') {
      setIsLoading(true)
      const result = await Methods.getFilesReference({ nameFile: fileItem.nameFile })
      setIsLoading(false)
      console.log('Entrando a reference')
      setNameFileData(result.data.referenceFile)

      const editD = {
        id: fileItem.id,
        nameFile: fileItem.nameFile,
        nameFileData: result.data.referenceFile,
      }

      setNewFile({ ...newFile, ...editD })
    }
  }, [])

  const handleFileChange = event => {
    setFileData(event.target.files[0])
    setNameFileData(event.target.files[0].name)
    handleFileData({ value: event.target.files[0].name, field: 'nameFileData' })
    inputFileRef.current.value = null
    setIsFileChanged(true)
  }
  // const handleImageChange = event => {
  //   setImageData(event.target.files[0])
  //   setNameImageData(event.target.files[0].name)
  //   handleFileData({ value: event.target.files[0].name, field: 'nameImageData' })
  //   inputImageRef.current.value = null
  //   setIsImageChanged(true)
  // }

  const handleFileIconClick = () => {
    inputFileRef.current.click()
  }
  // const handleImageIconClick = () => {
  //   inputImageRef.current.click()
  // }

  const saveFile = async () => {
    setIsLoading(true)
    if (newFile.nameFile === '' || newFile.nameFileData === null) {
      setIsLoading(false)
      notify('Favor de llenar todos los campos.', 'warning')
      return
    }

    if (action === 'create') {
      if (!fileData) {
        setIsLoading(false)
        notify('Favor de llenar todos los campos..', 'warning')
        return
      }
      // if (!imageData) {
      //   setIsLoading(false)
      //   notify('Favor de llenar todos los campos', 'warning')
      //   return
      // }
    }

    if (!(await validateData())) {
      setIsLoading(false)
      return
    }

    action === 'create' ? registerTrainingFiles() : updateTrainingFiles()
  }

  const handleSelectChange = (event) => {
    console.log(selectedValue)
    setSelectedValue(event ? event.value : null)
  }

  const putFileToS3 = async (file, type) => {
    const paramsFile = {
      path: `system/${type}`,
      fileName: file.name,
      type: 'putObject',
      contentType: file.type || 'application/octet-stream'
    }
    const presignedFileURL = await Methods.generatePresignedURL(paramsFile)

    const reader = new FileReader()

    reader.readAsArrayBuffer(file)
    reader.onload = async () => {
      const data = reader.result

      await Methods.putFileToS3(presignedFileURL.data, data, file.type || 'application/octet-stream')
    }
  }
  // funcion para registrar el archivo de entrenamiento
  const registerTrainingFiles = async () => {
    try {
      console.log(selectedValue)
      await Methods.postFilesTraining({ ...newFile }, selectedValue, userId)

      await putFileToS3(fileData, 'files')
      // await putFileToS3(imageData, 'images')

      notify('Video agregado correctamente', 'success')
      navigate('/TrainingFiles')
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      notify(error.response?.data?.message, 'error')
    }
    setIsLoading(false)
  }

  const updateTrainingFiles = async () => {
    try {
      await Methods.putFilesTraining({ ...newFile }, userId, isFileChanged, isImageChanged)

      if (isFileChanged) {
        await putFileToS3(fileData, 'files')
      }
      if (isImageChanged) {
        await putFileToS3(imageData, 'images')
      }

      notify('Archivo actualizado correctamente', 'success')
      navigate('/TrainingFiles')
    } catch (error) {
      console.log(error)
      notify(error.response.data.message, 'error')
    }
    setIsLoading(false)
  }

  const handleFileData = async ({ value, field }) => {
    setNewFile({
      ...newFile,
      [field]: value,
    })
  }

  const validateData = async () => {
    if (!newFile.nameFile.trim('').length) {
      notify('Nombre de archivo inválido, favor de revisar', 'warning')
      return false
    }
    return true
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <div className='body'>
          <div className='flex'>
            <button className='back-arrow' onClick={() => navigate('/TrainingFiles')}>
              &lt;
            </button>
            <header>
              {newFile?.nameFile ? newFile.nameFile : 'Cargar nuevo video'}
            </header>
          </div>
          <h6>
            {newFile?.nameFile ? newFile?.nameFile : 'Cargar nuevo video'}
          </h6>
          <div className='main'>
            <div className='d-flex align-items-center'>
              <div className='col-3 m-2'>Nombre</div>
              <input
                className='input col-8 mb-3 mt-2'
                name='nameFile'
                defaultValue={newFile?.nameFile}
                onChange={event =>
                  handleFileData({ value: event.target.value, field: 'nameFile' })
                }
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-3 m-2'>Adjuntar nuevo video</div>
                <button
                    className='input col-3'
                    style={{ backgroundColor: 'skyblue' }}
                    onClick={e => handleFileIconClick()}
                    >
                        Seleccione Archivo
                </button>
                <input
                    type='file'
                    accept='.mp4'
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    onChange={handleFileChange}
                    />
                <input
                    className='input col-5 mb-2 mt-2'
                    defaultValue={nameFileData}
                    disabled
                />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-3 m-2'>Seleccionar el tipo de paquete para aplicar el video</div>
              <Select
                  styles={selectStyle}
                  placeholder="Selecciona una opción"
                  options={context.clientTypes}
                  isDisabled={context.user.userType !== 'admin'}
                  onChange={ handleSelectChange }
                />
            </div>

            <button className='btn btn-dark mt-5' name='saveFile' onClick={saveFile}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
