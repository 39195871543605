import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router'
import './ClientDetail.css'
import { useContext, useEffect, useState } from 'react'
import Methods from '../../../utils/Methods'
import { notify } from '../../../utils/notify'
import { ToggleSwitch } from '../../../components/ToggleSwitch'
import Select from 'react-select'
import { UserContext } from '../../../context/UserContext'
import Spinner from '../../../components/Spinner'

export default function ClientDetail() {
  const location = useLocation()
  const { user, action } = location.state
  const [showPassword, setShowPassword] = useState([false, false])
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [packageInfo, setPackageInfo] = useState([])
  const [endDay, setEndDay] = useState('')
  const [showSupportInfo, setShowSupportInfo] = useState(false)
  const [newUser, setNewUser] = useState({
    company: '',
    name: '',
    password: '',
    clientTypeId: '',
    status: 'active',
    accessEnd: '',
  })
  const [checkBoxValue, setCheckBoxValue] = useState(
    user?.status !== 'inactive'
  )
  const [isLoading, setIsLoading] = useState(false)
  const __emailValidator =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  const context = useContext(UserContext)
  const selectStyle = {
    control: base => ({
      ...base,
      borderRadius: 10,
      paddingLeft: 10,
    }),
  }
  const navigate = useNavigate()

  useEffect(() => {
    setNewUser({ ...newUser, ...user })
    context.getClientTypes().then(clientTypes => {
      getPackageInfo(user?.clientTypeId, clientTypes)
    })
  }, [])

  const getPackageInfo = async (clientTypeId, clientTypes) => {
    const packageInfo = clientTypes[clientTypeId - 1]?.info
    setPackageInfo(packageInfo ? packageInfo.split(',') : [''])
  }

  const saveUser = async () => {
    newUser.accessEnd = endDay
    setIsLoading(true)
    if (Object.values(newUser).some(x => x === '')) {
      setIsLoading(false)
      notify('Favor de llenar todos los campos', 'warning')
      return
    }
    if (!(await validateData())) {
      setIsLoading(false)
      return
    }

    action === 'create' ? registerClient() : updateClient()
  }

  const registerClient = async () => {
    await Methods.addUser({ ...newUser, userType: 'client' })
      .then(() => {
        setIsLoading(false)
        notify('Usuario agregado correctamente', 'success')
        navigate('/clients')
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
        if (error.response.data === 'Registro de usuario exitoso') {
          notify('Usuario agregado correctamente', 'success')
          navigate('/clients')
        } else {
          notify(error.response.data, 'error')
        }
      })
  }

  const updateClient = async () => {
    await Methods.updateUser({
      user: {
        ...newUser,
        userType: 'client',
      },
      isPasswordEdited: newPassword,
    })
      .then(() => {
        setIsLoading(false)
        notify('Datos actualizados correctamente', 'success')
        navigate('/clients')
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  const handleUserData = async ({ value, field }) => {
    setNewUser({
      ...newUser,
      [field]: value,
    })
    if (field === 'clientTypeId') {
      getPackageInfo(value, context.clientTypes)
    }
  }

  const statusChecked = async () => {
    handleUserData({
      value: newUser.status === 'inactive' ? 'active' : 'inactive',
      field: 'status',
    })
    setCheckBoxValue(!checkBoxValue)
  }

  const confirmPassword = async event => {
    setNewPassword(event.target.value)
    handleUserData({ value: oldPassword, field: 'password' })
  }

  const validateData = async () => {
    if (!newUser.name.trim('').length) {
      notify('Nombre de usuario inválido, favor de revisar', 'warning')
      return false
    }
    if (!__emailValidator.test(newUser.email)) {
      notify('Correo inválido, favor de revisar', 'warning')
      return false
    }
    if (!newPassword && !oldPassword) return true
    if (oldPassword !== newPassword) {
      notify('Las contraseñas no son iguales', 'warning')
      return false
    }
    if (oldPassword.length < 8) {
      notify('La contraseña debe tener un minimo de 8 caracteres', 'warning')
      return false
    }
    if (!/(\d)/.test(oldPassword)) {
      notify('La contraseña debe tener al menos un número', 'warning')
      return false
    }
    if (endDay === '') {
      notify('Se debe establecer un periodo de vencimiento', 'warning')
      return false
    }
    return true
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <div className='body'>
          <div className='flex'>
            {context.user.userType === 'admin' ? (
              <button
                className='back-arrow'
                onClick={() => navigate('/clients')}
              >
                &lt;
              </button>
            ) : (
              ''
            )}
            <header>
              {newUser?.company ? newUser?.company : 'Nuevo cliente'}
            </header>
          </div>
          <h6>
            {context.user.userType !== 'admin' ? 'Usuario' : 'Clientes'} &gt;{' '}
            {newUser?.name ? newUser?.name : 'Nuevo cliente'}{' '}
          </h6>
          <div className='main'>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Empresa</div>
              <input
                className={`input col-4 mb-2 mt-2' ${
                  context.user.userType !== 'admin' ? 'disabled' : ''
                }`}
                name='company'
                disabled={context.user.userType !== 'admin'}
                defaultValue={newUser?.company}
                onChange={event =>
                  handleUserData({
                    value: event.target.value,
                    field: 'company',
                  })
                }
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Nombre</div>
              <input
                className={`input col-4 mb-2 mt-2' ${
                  context.user.userType !== 'admin' ? 'disabled' : ''
                }`}
                name='name'
                disabled={context.user.userType !== 'admin'}
                defaultValue={newUser?.name}
                onChange={event =>
                  handleUserData({ value: event.target.value, field: 'name' })
                }
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Correo</div>
              <input
                className={`input col-4 mb-2 mt-2' ${
                  context.user.userType !== 'admin' ? 'disabled' : ''
                }`}
                defaultValue={newUser?.email}
                disabled={context.user.userType !== 'admin'}
                onChange={event =>
                  handleUserData({ value: event.target.value, field: 'email' })
                }
              />
            </div>
            {context.user.userType !== 'admin' ? (
              ''
            ) : (
              <div className='d-flex align-items-center'>
                <div className='col-2 m-2'>Contraseña</div>
                <input
                  type={showPassword[0] ? 'text' : 'password'}
                  className={`input col-4 mb-2 mt-2' ${
                    context.user.userType !== 'admin' ? 'disabled' : ''
                  }`}
                  defaultValue={newUser?.password}
                  onChange={event => setOldPassword(event.target.value)}
                />
                <FontAwesomeIcon
                  icon={showPassword[0] ? faEyeSlash : faEye}
                  className='eyeIcon'
                  disabled={context.user.userType !== 'admin'}
                  onClick={() =>
                    setShowPassword([!showPassword[0], showPassword[1]])
                  }
                />
              </div>
            )}
            {context.user.userType === 'admin' && oldPassword ? (
              <div className='d-flex align-items-center'>
                <div className='col-2 m-2'>Confirmar contraseña</div>
                <input
                  type={showPassword[1] ? 'text' : 'password'}
                  className='input col-4 mb-2 mt-2'
                  onBlur={event => confirmPassword(event)}
                />
                <FontAwesomeIcon
                  icon={showPassword[1] ? faEyeSlash : faEye}
                  className='eyeIcon'
                  onClick={() =>
                    setShowPassword([showPassword[0], !showPassword[1]])
                  }
                />
              </div>
            ) : (
              ''
            )}

            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Caducidad de licencia</div>
              <input
                disabled={context.user.userType === 'client'}
                type='date'
                defaultValue={newUser?.accessEnd}
                className='input col-4 mb-2 mt-2'
                onChange={event => setEndDay(event.target.value)}
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Paquete</div>
              <div className='col-4'>
                <Select
                  styles={selectStyle}
                  placeholder={
                    context.clientTypes
                      ? context.clientTypes[newUser.clientTypeId - 1]?.label
                      : ''
                  }
                  options={context.clientTypes}
                  isDisabled={context.user.userType !== 'admin'}
                  onChange={event =>
                    handleUserData({
                      value: event.value,
                      field: 'clientTypeId',
                    })
                  }
                />
              </div>
              <div className='package-tooltip'>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className='package-icon'
                />
                <div className='package-info'>
                  {packageInfo.map(info => (
                    <li key={info}>{info}</li>
                  ))}
                </div>
              </div>
            </div>
            {context.user.userType !== 'admin' ? (
              ''
            ) : (
              <div className='d-flex align-items-center'>
                <div className='col-2 m-2'>Estatus</div>
                <div className='togleswitch'>
                  <ToggleSwitch
                    label=' '
                    handleCheckBox={() => statusChecked()}
                    checkBoxValue={checkBoxValue}
                    isDisabled={context.user.userType !== 'admin'}
                  />
                </div>
              </div>
            )}

            {context.user.userType !== 'admin' ? (
              ''
            ) : (
              <button
                className='btn btn-dark'
                name='saveUser'
                onClick={saveUser}
              >
                Guardar
              </button>
            )}
          </div>
          {showSupportInfo ? (
            <div className='supportTooltip'>
              <div className='m-2'>
                Para cualquier problema o pregunta, no dude en ponerse en
                contacto con nuestro equipo de soporte. Puede enviarnos un
                correo electrónico a <u>encuesta.sys@caintra.org.mx</u> o llamar
                al teléfono 811-611-9951. <br />
                Estaremos encantados de ayudarle con cualquier problema que
                pueda tener.
              </div>
            </div>
          ) : (
            ''
          )}
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className='supportIcon'
            onClick={() => setShowSupportInfo(!showSupportInfo)}
          />
        </div>
      </div>
    </>
  )
}
