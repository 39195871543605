import './Modal.css'
import { useState, useEffect, useContext } from 'react'
import Methods from '../utils/Methods'
import { UserContext } from '../context/UserContext'

const ModalSurvey = ({
  isOpen,
  onCancel,
  cancelLabel,
  title,
  videoId,
}) => {
  const [currentUserDetails, setCurrentUserDetails] = useState([])
  const [answers, setAnswers] = useState([])
  const [resultsModalOpen, setResultsModalOpen] = useState(false) // Controla el estado del modal de resultados
  const [results, setResults] = useState(null) // Almacena los resultados

  const context = useContext(UserContext)

  useEffect(() => {
    if (videoId) {
      const fetchSurveyDetails = async () => {
        try {
          const surveyData = await Methods.getSurvey(videoId)
          console.log('Survey Data:', surveyData) // Verifica la respuesta completa

          // Accede a los datos relevantes
          if (
            surveyData &&
            surveyData.data &&
            surveyData.data.result &&
            surveyData.data.result.length > 0 &&
            surveyData.data.result[0].trainingQuestions.length > 0
          ) {
            const trainingQuestions = surveyData.data.result[0].trainingQuestions
            console.log('Training Questions:', trainingQuestions) // Verifica las preguntas

            // Transforma las preguntas basado en posición
            const transformedQuestions = trainingQuestions.map((item, index) => {
              const itemValues = Object.values(item) // Obtén los valores del objeto en un array
              return {
                questionId: index + 1,
                question: itemValues[0], // Primera posición es la pregunta
                answers: itemValues.slice(1, 4), // Siguientes tres posiciones son respuestas
                correctAnswer: itemValues[4], // Última posición es la respuesta correcta
              }
            })

            // Actualiza el estado
            setCurrentUserDetails(transformedQuestions)
            setAnswers(
              transformedQuestions.map((detail) => ({
                questionId: detail.questionId,
                selectedAnswer: '',
              }))
            )

            console.log('Transformed Questions:', transformedQuestions) // Verifica el formato transformado
          } else {
            console.log('No se encontraron preguntas de entrenamiento.')
          }
        } catch (error) {
          console.error('Error al obtener los detalles de la encuesta:', error)
        }
      }

      fetchSurveyDetails()
    }
  }, [videoId])

  const handleAnswerChange = (questionId, selectedAnswer) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.questionId === questionId
          ? { ...answer, selectedAnswer }
          : answer
      )
    )
  }

  const handleSubmit = async () => {
    const correctCount = answers.filter((answer) =>
      currentUserDetails.find(
        (detail) =>
          detail.questionId === answer.questionId &&
          detail.correctAnswer === answer.selectedAnswer
      )
    ).length

    const incorrectCount = answers.length - correctCount

    const result = {
      correctCount,
      incorrectCount,
      total: answers.length,
      cal: (correctCount * 10) / answers.length,
    }

    const userId = context.user.id
    const userName = context.user.name

    await Methods.postResponse(result, videoId, userId, userName)

    // Almacena los resultados y abre el modal de resultados
    setResults({ correct: correctCount, incorrect: incorrectCount })
    setResultsModalOpen(true)
  }

  const closeResultsModal = () => {
    setResultsModalOpen(false)
    onCancel() // Cierra el modal principal
  }

  return (
    <>
      {/* Modal de Encuesta */}
      <div className={`modal ${isOpen ? 'modal-open' : ''}`} onClick={onCancel}>
        <div className='modal_dialog2' onClick={(e) => e.stopPropagation()} style={modal}>
          <h3>{title}</h3>

          {currentUserDetails && currentUserDetails.length > 0 ? (
            <div>
              <h4>Responde las preguntas</h4>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={headerCellStyle}>Pregunta</th>
                    <th style={headerCellStyle}>Respuestas</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUserDetails.map((detail, index) => (
                    <tr
                      key={index}
                      style={{
                        ...rowStyle,
                        backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                      }}
                    >
                      <td style={cellStyle}>{detail.question}</td>
                      <td style={cellStyle}>
                        <div style={answerContainerStyle}>
                          {detail.answers.map((answer, i) => (
                            <label key={i} style={radioLabelStyle}>
                              <input
                                type='radio'
                                name={`question_${detail.questionId}`}
                                value={answer}
                                checked={answers.find(
                                  (ans) =>
                                    ans.questionId === detail.questionId &&
                                    ans.selectedAnswer === answer
                                )}
                                onChange={() =>
                                  handleAnswerChange(detail.questionId, answer)
                                }
                                style={radioInputStyle}
                              />
                              <span>{answer}</span>
                            </label>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button className='btn btn-primary' onClick={handleSubmit}>
                Enviar Respuestas
              </button>
            </div>
          ) : (
            <p>No hay detalles para mostrar.</p>
          )}

          {cancelLabel && (
            <button className='btn btn-danger' onClick={onCancel}>
              {cancelLabel}
            </button>
          )}
        </div>
      </div>

      {/* Modal de Resultados */}
      {resultsModalOpen && (
        <div className={'modal modal-open'} onClick={closeResultsModal}>
          <div className='modal_dialog' onClick={(e) => e.stopPropagation()}>
            <h3>Resultados</h3>
            <p>Respuestas correctas: {results.correct}</p>
            <p>Respuestas incorrectas: {results.incorrect}</p>
            <button className='btn btn-primary' onClick={closeResultsModal}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalSurvey

// Estilos y constantes quedan igual

const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: '0 8px', // Espaciado entre filas
  backgroundColor: '#fff',
}

const headerCellStyle = {
  padding: '15px',
  backgroundColor: '#FF9B71', // Azul suave
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'left',
  borderRadius: '8px 8px 0 0', // Bordes redondeados en la cabecera
}

const rowStyle = {
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para filas
  borderRadius: '8px',
}

const cellStyle = {
  padding: '15px',
  textAlign: 'left',
  verticalAlign: 'middle',
}

const answerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px', // Espacio entre opciones
}

const radioLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px', // Espacio entre el radio y el texto
  padding: '8px 12px',
  backgroundColor: '#f4f4f4',
  borderRadius: '6px',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
}

const radioInputStyle = {
  cursor: 'pointer',
}

const modal = {
  width: '80vw !important',
}
