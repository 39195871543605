import './Modal.css'
import Methods from '../utils/Methods'
import { useContext } from 'react'
import { UserContext } from '../context/UserContext'

const ModalVideo = ({
  isOpen,
  onAccept,
  acceptLabel,
  onCancel,
  cancelLabel,
  title,
  label,
  url,
  children,
  image,
  id,
  userId
}) => {
  const context = useContext(UserContext)
  const handleModalDialogClick = e => {
    e.stopPropagation()
  }
  let lastUpdateTime = Date.now()
  const playVideo = async () => {
    const res = await Methods.getTimeTraining(id, context.user.id)
    console.log('comenzando en ', res.data.data.timeView)
    const video = document.querySelector('.video')
    video.controls = false
    if (video) {
      video.currentTime = res.data.data.timeView
      video.addEventListener('timeupdate', (e) => {
        // localStorage.setItem('time', video.currentTime)
      })
      video.addEventListener('loadedmetadata', (e) => {
        if (res.data.data.timeView) {
          video.currentTime = res.data.data.timeView
        } else {
          video.currentTime = 0
        }
      })
      video.addEventListener('timeupdate', async () => {
        const currentTime = Date.now()
        const elapsedTime = currentTime - lastUpdateTime
        console.log(`Tiempo transcurrido: ${elapsedTime} ms`)
        if (elapsedTime > 5000) { // Si han pasado más de 5 segundos
          console.log('Guardando tiempo')
          await Methods.setTimeTraining(id, context.user.id, video.currentTime)
          lastUpdateTime = currentTime
        }
      })
      video.controls = false
      video.play().catch(error => {
        console.error('No se pudo reproducir el video:', error)
      })
    }
  }

  const pauseVideo = () => {
    const video = document.querySelector('.video')
    if (video) {
      video.pause()
    }
  }

  return (
    <div className={`modal ${isOpen && 'modal-open'}`} onClick={onCancel}>
      <div className='modal_dialog' onClick={handleModalDialogClick}>
        {image ? (
          <div>
            <img src={image} className='image'></img>{' '}
          </div>
        ) : (
          ''
        )}
        <div className='modal-title'>
          <h3>{title}</h3>
        </div>
        <video key={url} className='video' >
          <source src={url && url.trim() !== '' ? url : ''} />
          Your browser does not support the video tag.
        </video>
        <div className='modal-input'>{label}</div>

        {children}
        <button className='btn btn-info modal-input' onClick={ () => {
          playVideo()
        }}>
          Reanudar reproducción
        </button>
        <button className='btnWarning btn btn-warning' onClick={ () => {
          pauseVideo()
        }}>
          Pausar reproducción
        </button>
        {cancelLabel ? (
          <button className='btn btn-danger modal-input' onClick={ () => {
            onCancel()
            pauseVideo()
          }}>
            {cancelLabel}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default ModalVideo
